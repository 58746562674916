<template>
  <div class="wrapper">
    <div class="card shadow-sm d-block billing-card">
      <div style="padding: 7px; float: left;">{{ plan.message }}</div>
      <b-button
        :to="{ name: 'billing' }"
        size="sm"
        variant="outline-primary"
        class="float-right mt-1 mr-1"
        ><i class="far fa-credit-card"></i> Manage Billing</b-button
      >
    </div>

    <div class="card-deck mx-0">
      <div class="card shadow-sm profile-card ml-0">
        <div class="card-body">
          <h5 class="card-title">Avatar</h5>

          <div class="text-center">
            <img id="account-avatar" :src="avatar" alt="Person" />
          </div>
          <div id="image-response"></div>
          <div class="text-center">
            <label
              class="btn btn-outline-primary btn-file mr-1"
              for="fileUploadAvatar"
            >
              <b-spinner small v-if="uploading"></b-spinner>
              <span class="sr-only" v-if="uploading">Loading...</span>
              <i class="fas fa-cloud-upload-alt mr-1" v-if="!uploading"></i>
              <span v-if="!uploading">Upload</span>
              <input id="fileUploadAvatar" type="file" @change="uploadImage" />
            </label>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteAvatar"
            >
              <i class="far fa-trash-alt"></i> Remove
            </b-button>
          </div>
        </div>
      </div>

      <div class="card shadow-sm profile-card">
        <div class="card-body">
          <h5 class="card-title">Details</h5>
          <form @submit.prevent="save">
            <div class="form-group">
              <label for="inpName">Name</label>
              <input
                name="name"
                type="text"
                class="form-control data-field"
                id="inpName"
                placeholder="e.g. Joe Smith"
                required
                maxlength="100"
                data-error="Name is required."
                v-model="user.name"
              />
              <span
                class="glyphicon form-control-feedback"
                aria-hidden="true"
              ></span>
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <label for="inpEmail">Email</label>
              <input
                name="email"
                type="text"
                class="form-control data-field"
                id="inpEmail"
                placeholder="e.g. joe.smith@company.com"
                required
                maxlength="256"
                data-error="Email is required."
                v-model="user.email"
              />
              <span
                class="glyphicon form-control-feedback"
                aria-hidden="true"
              ></span>
              <div class="help-block with-errors"></div>
            </div>
            <b-button type="submit" variant="outline-success">
              <i class="far fa-save"></i> Save
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-2"
              v-b-modal.modal_change_password
            >
              Change Password
            </b-button>
          </form>
        </div>
      </div>

      <div class="card shadow-sm profile-card mr-0">
        <div class="card-body">
          <h5 class="card-title">Preferences</h5>

          <div class="form-group">
            <b-form-checkbox
              v-model="preferences.number_format"
              @change="
                updatePreference('number_format', 'fractions', 'decimals')
              "
              switch
            >
              Show Ingredient Amounts as Fractions
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal_reauth"
      title="Password Needed"
      header-bg-variant="warning"
      ok-variant="warning"
      @ok="updateEmail"
    >
      <b-alert :show="alert.show" variant="danger"
        ><i class="fas fa-exclamation-circle"></i> {{ alert.message }}</b-alert
      >
      <p class="my-1">
        Please re-enter your password to perform this operation:
      </p>
      <b-form-input
        v-model="user.password"
        placeholder="e.g. password"
        type="password"
      ></b-form-input>
    </b-modal>

    <b-modal
      id="modal_change_password"
      title="Change Password"
      header-bg-variant="primary"
      header-text-variant="light"
      ok-variant="primary"
      @ok="changePassword"
      @hide="validatePasswordChange"
    >
      <b-alert :show="alert.show" variant="danger"
        ><i class="fas fa-exclamation-circle"></i> {{ alert.message }}</b-alert
      >
      <b-form-group
        label="Existing Password"
        label-for="input-existing-password"
        :invalid-feedback="password.existingFeedback"
        :state="password.existingState"
      >
        <b-form-input
          id="input-existing-password"
          v-model="password.existing"
          :state="password.existingState"
          trim
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="New Password"
        label-for="input-new-password"
        :invalid-feedback="password.newFeedback"
        :state="password.newState"
      >
        <b-form-input
          id="input-new-password"
          v-model="password.new"
          :state="password.newState"
          trim
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Confirm Password"
        label-for="input-confirm-password"
        :invalid-feedback="password.confirmFeedback"
        :state="password.confirmState"
      >
        <b-form-input
          id="input-confirm-password"
          v-model="password.confirm"
          :state="password.confirmState"
          trim
          type="password"
        ></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import firebase from "firebase";
import FirebaseMixin from "../mixins/Firebase";
import UtilsMixin from "../mixins/Utils";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Profile",
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      ref: "users",
      plan: {
        message: ""
      },
      user: {
        name: "",
        email: "",
        originalEmail: "",
        password: ""
      },
      profile: {
        avatar: ""
      },
      preferences: {
        number_format: false
      },
      avatar: require("../assets/img_avatar.png"),
      placeholder: require("../assets/img_avatar.png"),
      uploading: false,
      alert: {
        show: false,
        message: "Incorrect password"
      },
      password: {
        existing: "",
        existingState: null,
        existingFeedback: null,
        new: "",
        newState: null,
        newFeedback: null,
        confirm: "",
        confirmState: null,
        confirmFeedback: null
      }
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          this.bindObject(this.ref + "/" + uid + "/profile", null, "profile");
        }
      }
    },
    profile: {
      immediate: false,
      handler(profile) {
        if (profile.avatar) {
          this.getImageUrl("avatars/" + profile.avatar).then(url => {
            this.avatar = url;
          });
        }
        this.preferences.number_format =
          this.profile.number_format === "fractions" ? true : false;
      }
    }
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    }
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
    uploadImage() {
      const reader = new FileReader();
      const files = event.target.files;
      console.log(files);

      // Start button loading spinner
      this.uploading = true;

      if (files.length === 0) {
        // Stop button loading spinner
        this.uploading = false;
      } else {
        const file = files[0];
        const extension = file.name.split(".").pop();
        const filename = this.userUid + "/" + uuidv4() + "." + extension;
        console.log("File: " + filename);

        // Get storage reference
        const ref = firebase
          .storage()
          .ref()
          .child("avatars/" + filename);

        reader.addEventListener(
          "load",
          () => {
            ref.put(file).then(() => {
              this.profile.avatar = filename;
              this.saveProfile().then(() => {
                // Stop button loading spinner
                this.uploading = false;
              });
            });
          },
          false
        );

        if (file) {
          reader.readAsBinaryString(file);
        }
      }
    },
    deleteAvatar() {
      if (this.profile.avatar) {
        const ref = firebase
          .storage()
          .ref()
          .child("avatars/" + this.profile.avatar);
        ref
          .delete()
          .then(() => {
            this.profile.avatar = null;
            this.avatar = this.placeholder;
            this.saveProfile();
          })
          .catch(error => {
            if (error.code === "storage/object-not-found") {
              this.profile.avatar = null;
              this.avatar = this.placeholder;
              this.saveProfile();
            } else {
              console.log(error);
              window.toastr.error(
                "An error occurred while deleting the image. Please try again later."
              );
            }
          });
      }
    },
    saveProfile(hideAlerts = false) {
      const profile = { ...this.profile };

      return this.updateObject(
        this.ref + "/" + this.userUid + "/profile",
        null,
        profile
      )
        .then(() => {
          console.log("Profile saved successfully");
          this.setProfile(this.profile);
          if (!hideAlerts) {
            window.toastr.success("Profile saved successfully");
          }
        })
        .catch(error => {
          console.log(error);
          if (!hideAlerts) {
            window.toastr.error(
              "An error occurred while saving the changes. Please try again later."
            );
          }
        });
    },
    save() {
      const user = firebase.auth().currentUser;

      user
        .updateProfile({
          displayName: this.user.name
        })
        .then(() => {
          let updatedUser = { ...this.user };
          delete updatedUser.profile;
          this.setUser(updatedUser);
          // Check if email has changed
          if (this.user.email !== this.user.originalEmail) {
            user
              .updateEmail(this.user.email)
              .then(() => {
                window.toastr.success("Profile updated successfully");
              })
              .catch(error => {
                console.log(error);
                if (error.code === "auth/requires-recent-login") {
                  this.alert.show = false;
                  this.$bvModal.show("modal_reauth");
                } else {
                  window.toastr.error(
                    "An error occurred updating your profile. Please try again later."
                  );
                }
              });
          } else {
            window.toastr.success("Profile updated successfully");
          }
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred updating your profile. Please try again later."
          );
        });
    },
    updateEmail() {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.user.originalEmail,
        this.user.password
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          // User re-authenticated.
          user
            .updateEmail(this.user.email)
            .then(() => {
              this.user.password = null;
              window.toastr.success("Profile updated successfully");
            })
            .catch(error => {
              console.log(error);
              window.toastr.error(
                "An error occurred updating your profile. Please try again later."
              );
            });
        })
        .catch(error => {
          console.log(error);
          if (error.code === "auth/wrong-password") {
            this.alert.show = true;
            this.$bvModal.show("modal_reauth");
          } else {
            window.toastr.error(
              "An error occurred updating your profile. Please try again later."
            );
          }
        });
    },
    validatePasswordChange(event) {
      // Perform validations
      let passed = true;
      if (!this.password.existing) {
        this.password.existingState = false;
        this.password.existingFeedback = "Please enter your current password.";
        passed = false;
      } else {
        this.password.existingState = null;
        this.password.existingFeedback = null;
      }
      if (!this.password.new || this.password.new.length === 0) {
        this.password.newState = false;
        this.password.newFeedback = "Please enter your new password.";
        passed = false;
      } else {
        this.password.newState = null;
        this.password.newFeedback = null;
      }
      if (!this.password.confirm || this.password.confirm.length === 0) {
        this.password.confirmState = false;
        this.password.confirmFeedback = "Please confirm your new password.";
        passed = false;
      } else if (this.password.confirm !== this.password.new) {
        this.password.confirmState = false;
        this.password.confirmFeedback = "Passwords do not match.";
        passed = false;
      } else {
        this.password.confirmState = null;
        this.password.confirmFeedback = null;
      }
      if (!passed) {
        event.preventDefault();
      }
    },
    changePassword() {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.user.originalEmail,
        this.password.existing
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          user
            .updatePassword(this.password.new)
            .then(() => {
              this.password.existing = "";
              this.password.new = "";
              this.password.confirm = "";
              window.toastr.success("Password changed successfully.");
            })
            .catch(error => {
              console.log(error);
              window.toastr.error(
                "An error occurred while changing your password. Please try again later."
              );
            });
        })
        .catch(error => {
          if (error.code === "auth/wrong-password") {
            this.alert.show = true;
            this.$bvModal.show("modal_change_password");
          } else {
            window.toastr.error(
              "An error occurred while changing your password. Please try again later."
            );
          }
        });
    },
    updatePreference(pref, trueVal, falseVal) {
      this.$nextTick().then(() => {
        this.profile[pref] = this.preferences[pref] ? trueVal : falseVal;
        this.saveProfile();
      });
    }
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.user.name = user.displayName;
        this.user.email = user.email;
        this.user.originalEmail = user.email;
      }
    });
  }
};
</script>

<style scoped>
.wrapper {
  max-width: 1100px;
  margin: 0 auto;
}

#account-avatar {
  height: 240px;
  width: 240px;
  border-radius: 120px;
  line-height: 240px;
  margin: 0 auto 20px auto;
  object-fit: cover;
  object-position: top;
}

#image-response {
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.billing-card {
  width: 100%;
  height: 41px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.profile-card {
  margin-left: 7.5px;
  margin-right: 7.5px;
}
</style>
